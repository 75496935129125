// extracted by mini-css-extract-plugin
export var ArtistDescription = "ChazBojorquez-module--ArtistDescription--hNxGO";
export var ArtistInfos = "ChazBojorquez-module--ArtistInfos--s3aLf";
export var ButtonWrapper = "ChazBojorquez-module--ButtonWrapper --UDTQt";
export var CardWrapper = "ChazBojorquez-module--CardWrapper--ka4t2";
export var CarrouselWrapper2 = "ChazBojorquez-module--CarrouselWrapper2--h7XqY";
export var Citations = "ChazBojorquez-module--Citations--OUNUU";
export var DescriptionWrapper = "ChazBojorquez-module--DescriptionWrapper--unEDX";
export var ImageWrapper = "ChazBojorquez-module--ImageWrapper--xpvES";
export var LinkWrapper = "ChazBojorquez-module--LinkWrapper--aYfH6";
export var MobileProtrait = "ChazBojorquez-module--MobileProtrait--xnlmO";
export var More = "ChazBojorquez-module--More--No6gT";
export var MoreButton = "ChazBojorquez-module--MoreButton--x-FiU";
export var NameWrapper = "ChazBojorquez-module--NameWrapper--92Q9H";
export var PdpWrapper = "ChazBojorquez-module--PdpWrapper--cdQIU";
export var PhotosWrapper = "ChazBojorquez-module--PhotosWrapper--gYnVA";
export var ProfilWrapper = "ChazBojorquez-module--ProfilWrapper--QY3Ye";
export var TitleWrapper = "ChazBojorquez-module--TitleWrapper--pBy4e";
export var Wrapper = "ChazBojorquez-module--Wrapper--mgtFG";